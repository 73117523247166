import { console } from "window-or-global";

export const getDateString = () => {
  var currentDate = new Date();
  var dd = currentDate.getUTCDate();
  var mm = currentDate.getUTCMonth() + 1;
  var hr = currentDate.getUTCHours();
  var min = currentDate.getUTCMinutes();
  var ms = currentDate.getUTCMilliseconds();

  var yyyy = currentDate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (min < 10) {
    min = '0' + min;
  }

  return `${yyyy}/${mm}/${dd}/${hr}_${min}_${ms}`;
};

export const dropDownYears = (dateDropdown, earliestYear) => {
  let currentYear = new Date().getFullYear();

  while (currentYear >= earliestYear) {
    let dateOption = document.createElement('option');
    dateOption.text = currentYear;
    dateOption.value = currentYear;
    dateDropdown.add(dateOption);
    currentYear -= 1;
  }
};


export const swipedetect = (el, callback) => {
  
  var touchsurface = el,
  swipedir,
  startX,
  startY,
  dist,
  distX,
  distY,
  threshold = 40, //required min distance traveled to be considered swipe
  restraint = 100, // maximum distance allowed at the same time in perpendicular direction
  allowedTime = 300, // maximum time allowed to travel that distance
  elapsedTime,
  startTime,
  handleswipe = callback || function(swipedir){}

  touchsurface.addEventListener('touchstart', function(e){
      var touchobj = e.changedTouches[0]
      swipedir = 'none'
      dist = 0
      startX = touchobj.pageX
      startY = touchobj.pageY
      startTime = new Date().getTime() // record time when finger first makes contact with surface
      e.preventDefault()
  }, false)

  touchsurface.addEventListener('touchmove', function(e){
      e.preventDefault() // prevent scrolling when inside DIV
  }, false)

  touchsurface.addEventListener('touchend', function(e){
      var touchobj = e.changedTouches[0]
      distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime // get time elapsed
      if (elapsedTime <= allowedTime){ // first condition for awipe met
          if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
              swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
          }
          else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
              swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
          }
      }
      handleswipe(swipedir)
      e.preventDefault()
  }, false)
}


export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  
  return (
      rect.top + (rect.height/2) >= 0 &&
      rect.left >= 0 &&
      rect.bottom - (rect.height/2) <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );
}

export const throttle = (callback, limit)  => {
  var wait = false;
  return function () {
      if (!wait) {
          callback.call();
          wait = true;
          setTimeout(function () {
              wait = false;
          }, limit);
      }
  }
}