import {throttle, isInViewport} from '../common/utils';

const createVideo = (videoWrap) => {
  
  if(videoWrap) {
    videoWrap.innerHTML = '';
  
  var breakforMobile = '768';
  var screenWidth = window.innerWidth;

  var video = document.createElement('video');

  videoWrap.appendChild(video);

  var mobileVideo = videoWrap.getAttribute('data-mobile-video');
  var desktopVideo = videoWrap.getAttribute('data-desktop-video');
  var mobileQuality = videoWrap.getAttribute('data-mobile-quality');
  var desktopQuality = videoWrap.getAttribute('data-desktop-quality');
  var autoplay = videoWrap.getAttribute('data-autoplay');
  var controls = videoWrap.getAttribute('data-controls');
  var muted = videoWrap.getAttribute('data-muted');
  var loop = videoWrap.getAttribute('data-loop');
  var endpoint = videoWrap.getAttribute('data-endpoint');
  var defaultHost = videoWrap.getAttribute('data-defaultHost');
  var fullscreen = videoWrap.getAttribute('data-fullscreen');
  !mobileQuality.length ? (mobileQuality = '240p') : null;
  !desktopQuality.length ? (desktopQuality = '240p') : null;

  video.setAttribute('preload', 'auto');
  video.setAttribute('id', desktopVideo)
  if (controls === 'true') {
    video.controls = true;
  }
  if (muted === 'true') {
    video.muted = true;
  }
  if (loop === 'true') {
    video.loop = true;
  }

  var source1 = document.createElement('source');
  var source2 = document.createElement('source');

  var url = 'https://' + defaultHost + '/v/' + endpoint + '/';
  

  if (mobileVideo.length && screenWidth <= breakforMobile) {
    source1.setAttribute('src', url + mobileVideo + '/mp4_' + mobileQuality);
    source2.setAttribute('src', url + mobileVideo + '/webm_' + mobileQuality);
    video.setAttribute('poster', url + mobileVideo);
  } else {
    video.setAttribute('poster', url + desktopVideo);
    source1.setAttribute('src', url + desktopVideo + '/mp4_' + desktopQuality);
    source2.setAttribute('src', url + desktopVideo + '/webm_' + desktopQuality);
  }

  video.appendChild(source1);
  video.appendChild(source2);

  if (fullscreen === 'true') {
    video.setAttribute('class', 'mul-dc-fs-cover-media');
  }
  }
  
  
};

const playVideo = video => {
  
  video.setAttribute('playsinline', true);
    var playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then(function (_) {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(function (error) {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
}

const checkVids = (videoWrap) => {
  
  for (let i = 0; i < videoWrap.length; i++) {
    const video = document.getElementById(videoWrap[i].getAttribute('data-desktop-video'));
    const autoplay = videoWrap[i].getAttribute('data-autoplay');
    
    if(isInViewport(videoWrap[i])) {
      if (video.paused && autoplay === 'true') {
        //video.play()
        playVideo(video)
      }
    }
    else {
      if(!video.paused) {
        video.pause()  
      }
    }
  }
}


export const initVideoChooser = () => {
  const videoFrames = document.getElementsByClassName('mul-dc-video')
  const vids = [];
  
  if (videoFrames.length >=1) {
    
    let videoWrap = document.getElementsByClassName('mul-dc-video');    
    
    for (let i = 0; i < videoWrap.length; i++) {
      createVideo(videoWrap[i]);

      var desktopVideo = videoWrap[i].getAttribute('data-desktop-video');
      var autoplay = videoWrap[i].getAttribute('data-autoplay');
      var vidObj = {name:desktopVideo, play:autoplay}
      vids.push(vidObj);
    }
    
    const videoName = vids[0].name
    const firstVideo = document.getElementById(videoName)
    
    const play = vids[0].play;
   
    if (isInViewport(firstVideo) && play === 'true') {
        playVideo(firstVideo)
    }

    document.addEventListener('scroll', function () {
      throttle(checkVids(videoWrap), 3000)
    })
  
  }
};

export const initActiveSlideVideo = videoFrame => {
  
  const video = videoFrame.getElementsByClassName('mul-dc-fs-cover-media');
  
  playVideo(video[0])

};
